// Topbar.js
import React from 'react';
import SocialIcons from './Socialicons';
const Topbar = () => {
  return (
    <div className="bg-top d-none padRL10 d-lg-block">
      <div className="row gx-0">
        <div className=" col-lg-10 text-center text-lg-start mb-2 mb-lg-0">
        <div className="col-lg-2  d-inline-flex align-items-center position-relative top-10">
            <small className="text-light text-center"><i className="fa fa-envelope-open me-2"></i>contactus@neinus.com</small>
          </div>
          <div className=" col-lg-8 d-inline-flex align-items-center position-relative top-10">
            {/* <small className="me-3 text-light"><i className="fa fa-map-marker-alt me-2"></i>Netherlands-India-USA, </small> */}
            <small className="me-3 text-light "><i className="fa fa-phone-alt me-2"></i> Sathyamangalam: +91 7200864223 , +91 7200864623 , +91 9965618507</small>
           </div>
         
        </div>
       <SocialIcons />
      </div>
    </div>
  );
}

export default Topbar;
