// Courses.js
import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import './Courses.css'; // Import custom CSS for additional styling
import courses from '../utils/courses.json'

const Courses = () => {
  const [expanded, setExpanded] = useState(Array(courses.length).fill(false));

  const toggleExpand = (index) => {
    setExpanded((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4 custom-heading">Software Training Courses </h2>
      <div className="row">
        {courses.map((course, index) => (
          <div key={index} className="col-lg-4 col-md-6 col-sm-12 mb-4">
            <div className="card h-100 course-card">
              <div className="card-body">
                <h5 className="card-title bg-primary text-white p-2 rounded">{course.title}</h5>
                <p className="card-text bg-light p-2 rounded">
                  {expanded[index] ? course.description : `${course.description.substring(0, 100)}...`}
                  <button className="btn btn-link p-0" onClick={() => toggleExpand(index)}>
                    {expanded[index] ? 'Read Less' : 'Read More'}
                  </button>
                </p>
                <p className="card-text"><small className="text-muted">Duration: {course.duration}</small></p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Courses;
