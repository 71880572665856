

import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import 'bootstrap/dist/css/bootstrap.min.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    setLocations([
      {
        name: "Sathyamangalam:",
        address:
          "No 139/1A, Kamadhenu Nagar, Opposite To BIT College, Sathy Athani Road Bus Stop, Sathyamangalam - 638503, Erode Dt, TamilNadu",
        phone:
          "+91 7200864223 , +91 7200864623 , +91 9965618507",
        email: "contactus@neinus.com",
      },
    ]);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value, // Ensure the value is stored as it is, without trimming
    }));
  
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    switch (name) {
      case "name":
        setErrors((prevErrors) => ({
          ...prevErrors,
          name: value.length > 0 ? "" : "Name is required",
        }));
        break;
      case "email":
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: emailRegex.test(value) ? "" : "Please enter a valid email address",
        }));
        break;
      case "message":
        setErrors((prevErrors) => ({
          ...prevErrors,
          message: value.length > 0 ? "" : "Message is required",
        }));
        break;
      default:
        break;
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (Object.values(errors).every((error) => error === "")) {
      setLoading(true);
      try {
        await emailjs.send(
          "service_3g3rqri",
          "template_amdg2te",
          formData,
          "22Rk1x7nRvWpUw37o"
        );
        setToastMessage({ type: "success", text: "Your message has been sent successfully!" });
        setFormData({ name: "", email: "", message: "" });
      } catch (error) {
        setToastMessage({ type: "error", text: "Failed to send your message, please try again." });
      } finally {
        setLoading(false);
      }
    } else {
      setToastMessage({ type: "error", text: "Please correct the errors before submitting." });
    }
  };

  useEffect(() => {
    if (toastMessage) {
      const timer = setTimeout(() => setToastMessage(null), 5000);
      return () => clearTimeout(timer);
    }
  }, [toastMessage]);

  return (
    <div className="container my-5">
  
      <div className="row">
        {/* Contact Form */}
        <div className="col-md-6">
          <div className="card shadow">
          <div className="card-body">
  <h2 className="card-title mb-4 text-primary">Contact Us</h2>
  <form onSubmit={handleSubmit}>
    {/* Message Display */}
    {toastMessage && (
      <div
        className={`alert ${
          toastMessage.type === "success" ? "alert-success" : "alert-danger"
        } mb-3`}
        role="alert"
      >
        {toastMessage.text}
      </div>
    )}

    {/* Name Input */}
    <div className="mb-3">
      <label htmlFor="name" className="form-label">
        Name
      </label>
      <input
        type="text"
        id="name"
        name="name"
        value={formData.name}
        onChange={handleChange}
        className={`form-control w-75 ${errors.name ? "is-invalid" : ""}`}
        required
      />
      {errors.name && <div className="invalid-feedback">{errors.name}</div>}
    </div>

    {/* Email Input */}
    <div className="mb-3">
      <label htmlFor="email" className="form-label">
        Email
      </label>
      <input
        type="email"
        id="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        className={`form-control w-75 ${errors.email ? "is-invalid" : ""}`}
        required
      />
      {errors.email && <div className="invalid-feedback">{errors.email}</div>}
    </div>

    {/* Message Input */}
    <div className="mb-3">
  <label htmlFor="message" className="form-label">
    Message
  </label>
  <textarea
    id="message"
    name="message"
    value={formData.message}
    onChange={handleChange}
    className={`form-control w-75 ${errors.message ? "is-invalid" : ""}`}
    rows="4" // Adjust the row count based on how many lines you want to see by default
    placeholder="Type your message here..."
    required
  />
  {errors.message && <div className="invalid-feedback">{errors.message}</div>}
</div>

    {/* Submit Button */}
    <div className="d-flex justify-content-center">
      <button type="submit" className="btn btn-primary w-50" disabled={loading}>
        {loading ? "Sending..." : "Submit"}
      </button>
    </div>
  </form>
</div>

          </div>
        </div>

        {/* Location Details */}
        <div className="col-md-5">
          <div className="card shadow">
            <div className="card-body">
              <h2 className="card-title mb-4 text-primary">Our Location</h2>
              {locations.map((location, index) => (
                <div key={index} className="mb-4">
                  <h5 className="fw-bold">{location.name}</h5>
                  <p>{location.address}</p>
                  <p>
                    <strong>Phone:</strong><p>{location.phone}</p> 
                  </p>
                  <p>
                    <strong>Email:</strong> <p>{location.email}</p>
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
