import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import careersData from '../utils/careers.json';  // import the JSON data
import hiringImage from '../images/hiring.png';
// import ApplyJob from "./ApplyJob";
const Careers = () => {
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [positions, setPositions] = useState([]);

  useEffect(() => {
    setPositions(careersData);  // Set positions data from JSON
  }, []);

  const handleViewDetails = (id) => {
    setSelectedPosition(selectedPosition === id ? null : id);
  };

  return (
    <section className="career-section p-4 bg-light">
      <h2 className="text-center mb-4">Join Our Team</h2>
      <p className="text-center">
        We are happy to announce that Neinus Technologies is currently recruiting new members for our team. 
        We are looking for individuals with a passion for Digital Marketing, Web Development, Trainers, and Sales. 
        If you're interested, Send your resume to  <a href="mailto:contactus@neinus.com" className="text-primary">contactus@neinus.com</a>
      </p>
      <div><strong>Location : Sathyamangalam / Remote</strong></div>
      {/* <ApplyJob/> */}
      <div class="center-container">
      <img src={hiringImage} alt="We Are Hiring"/>
      </div>
      <div className="container mt-4">
        {positions.map((position) => (
          <div key={position.id} className="card mb-4 shadow-sm">
            <div className="card-body d-flex justify-content-between align-items-center">
              <div>
                <h5 className="card-title mb-0 text-primary">{position.title}</h5>
                <p className="card-text text-muted">{position.description}</p>
              </div>
              <button
                className="btn btn-outline-primary"
                onClick={() => handleViewDetails(position.id)}
                aria-expanded={selectedPosition === position.id}
              >
                {selectedPosition === position.id ? "Hide Details" : "View Details"}
              </button>
            </div>
            <div className={`career-details mt-3 ms-3 ${selectedPosition === position.id ? 'open' : ''}`}>
  {selectedPosition === position.id && (
    <>
      <h6 className="mb-2">Responsibilities:</h6>
      <ul className="pl-3">
        {position.responsibilities.map((responsibility, index) => (
          <li key={index}>{responsibility}</li>
        ))}
      </ul>
      <h6 className="mt-3 mb-2">Qualifications:</h6>
      <ul className="pl-3">
        {position.qualifications.map((qualification, index) => (
          <li key={index}>{qualification}</li>
        ))}
      </ul>
    </>
  )}
</div>

          </div>
        ))}
      </div>
    </section>
  );
};

export default Careers;
